import axios from 'axios';
import queryString from 'query-string';
import util from './Common/Assets/util';
import { retry } from 'rxjs/operators';
import * as pako from 'pako'

const BASE_URI = process.env.REACT_APP_API_URL;

const client = axios.create({
  baseURL: BASE_URI,
  json: true
});

const client1 = axios.create({
  baseURL: process.env.REACT_APP_PRESIGNED_PART_API_URL
  // baseURL: "https://hredj15ytl.execute-api.us-east-1.amazonaws.com/default"
  // baseURL: "https://mo9yfvbp1e.execute-api.us-east-1.amazonaws.com/dev"
});


class APIClient {
  constructor(user) {
    this.user = user;
    this.fullaccess = true;
    this.folderAccess = true;
    this.folderDelete = false;
    this.token = null;
    this.pageSize = 10;
    this.presentPage = 1;
    this.nextToken = '';
    this.prevToken = { 0: '' };
    this.glacierFileInfo = {};
    this.cancelMultipartUpload = false;
    this.selectedRows = [];
    this.changeInitiated = false;
    this.percentCompleted = [];
    this.multipartDetails = [];
    this.pasteStatus = [];
    this.deleteStatus = [];
    this.folderCopyData = [];
    this.folderCopyStop = false
    this.region = '';
    this.setInterceptor();
  }

  setInterceptor() {
    let callCount = 0;
    let callCount_client = 0;

    const sleepRequest_client = (milliseconds, originalRequest, cli) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(cli(originalRequest)), milliseconds);
      });
    };

    client1.interceptors.response.use(response => {
      return response;
    }, error => {
      const { config, response: { status } } = error;
      const originalRequest = config;
      ++callCount;
      if (status === 500 && (config.url.includes('dev')) && callCount < 5) {
        return sleepRequest_client(5000, originalRequest, client1);
      } else {
        callCount = 0;
        error.reject = true;
        let error1 = { reject: true, error: error }
        return Promise.reject(error1);
      }
    });

    client.interceptors.response.use(response => {
      return response;
    }, error => {
      const { config, response: { status } } = error;
      const originalRequest = config;
      ++callCount_client;
      if (status === 500 && (config.url.includes('multipart')) && callCount_client < 3) {
        return sleepRequest_client(5000, originalRequest, client);
      } else if (config.url.includes('multipart')) {
        callCount_client = 0;
        error.reject = true;
        let error1 = { reject: true, error: error }
        return Promise.reject(error1);
      } else if (status === 401) {
        util.setFullAccess({ originalReq: originalRequest, client });
        return Promise.reject(error);
        // window.location.reload();
      } else {
        return Promise.reject(error);
      }
    });
  }
  userAuth() {
    let method = 'post';
    let url = '/api/login/';
    return client({
      method,
      url: url,
      data: JSON.parse(localStorage.getItem('okta-token-storage'))
    }).then(resp => {
      return resp.data['results'] ? resp.data['results'] : resp.data;
    }).catch((result) => {
      retry(3);
      this.userAuth();
    });
  }
  getEnvVar(token) {
    return this.perform('get', '/api/envs/', this.token);
  }
  getBucket(bucket_id) {
    if (bucket_id !== undefined) {
      return this.perform('get', `/api/folders/?bucket=${bucket_id}`, this.token)
    } else {
      return this.perform('get', `/api/buckets/`, this.token)
    }
  }
  getBucketWithId(bucket_id) {
    return this.perform('get', `/api/buckets/${bucket_id}/`, this.token)
  }
  getFolderWithId(folder_id) {
    return this.perform('get', `/api/folders/${folder_id}`, this.token)
  }
  getNotifications() {
    return this.perform('get', `/api/notifications/`, this.token);
  }
  setNotificationsasRead(data) {
    return this.post('post', `/api/notifications/hide_these_notifications/`, this.token, this.user, data);
  }
  setFeedback(data) {
    return this.post('post', `/api/files/add_user_feedback/`, this.token, this.user, data);
  }
  getDownloadURL(bucket, key, bucket_region) {
    return this.perform('get', `/api/files/download/?bucket=${bucket}&object_key=${key.replace(/&/g, '%26')}&region=${bucket_region}`, this.token);
  }
  getFolder(foldername, bucket) {
    let timeStamp = Math.floor(Date.now() / 1000);
    if (foldername !== undefined) {
      const query = { folder: foldername, bucket: bucket, timestamp:  timeStamp};
      let qryStr = queryString.stringify(query);
      return this.getPaginatedApi('GET', `/api/files/?${qryStr}`, this.token);
    }
  }
  getFolderNew(foldername, bucket, token, onlyFolder) {
    if (foldername !== undefined) {
      let next_token = ''
      if (token !== undefined) {
        next_token = token
      }

      if (foldername === " "){
        if (onlyFolder !== undefined) {
          return this.getPaginatedApi('GET', `/api/files/list_bucket/?bucket=${bucket}&maxkeys=1000&next_token=${next_token}&only_folders=true`, this.token)
        }else{
          return this.getPaginatedApi('GET', `/api/files/list_bucket/?bucket=${bucket}&maxkeys=1000&next_token=${next_token}`, this.token)
        }
      }else{
        let folder= foldername.replace(/\+/g, '%2B').replace(/&/g, '%26')
        if (onlyFolder !== undefined) {
          return this.getPaginatedApi('GET', `/api/files/list_bucket/?folder=${folder}&bucket=${bucket}&maxkeys=1000&next_token=${next_token}&only_folders=true`, this.token)
        }else{
          return this.getPaginatedApi('GET', `/api/files/list_bucket/?folder=${folder}&bucket=${bucket}&maxkeys=1000&next_token=${next_token}`, this.token)
        }
      }
    }
  }

  getFile(id) {
    return this.perform('get', `/api/files/${id['id']}`, this.token)
  }
  searchFileOld(path, filename) {
    const query = { path: path, filename: filename};
    let qryStr = queryString.stringify(query);
    return this.perform('get', `/api/files/search/?${qryStr}`, this.token);
  }
  searchFile(bucket, folder, filename) {
    return this.perform('get', `/api/files/search/?${folder === " " ? `` : `folder=` + folder + `&`}bucket=${bucket}&search_text=${filename}`, this.token)
  }
  renameFileName(bucket, objKey, filename) {
    const query = { bucket: bucket, object_key: objKey, newname: filename};
    let qryStr = queryString.stringify(query);
    return this.perform('get', `/api/files/rename/?${qryStr}`, this.token);
  }
  createNewFolder(bucketname, fileKey, bucket_region) {
    const query = { bucket: bucketname, object_key: fileKey, region: bucket_region};
    let qryStr = queryString.stringify(query);
    return this.perform('get', `/api/files/createNewFolder/?${qryStr}`, this.token);
  }
  restoreGlacierFile(bucketname, fileKey, fileName, bucket_region) {
    const query = { bucket: bucketname, object_key: fileKey, fileName: fileName, region: bucket_region};
    let qryStr = queryString.stringify(query);
    return this.perform('get', `/api/files/restore_from_glacier/?${qryStr}`, this.token);
  }
  addUpdateBucket(data) {
    return this.perform('post', `/api/buckets/`, this.token)
  }
  addUpdateFolder(user, data, bucket, folder) {
    return this.createFolder('post', `/api/folders/`, this.token, user, data, bucket, folder)
  }
  getPresignedUrl(qryStr) {
    return this.perform('get', `/api/files/upload/?${qryStr}`, this.token)
  }

  async postPresignedUrl(data) {
    return client1({
      method: 'post',
      url: '/',
      data,
      headers: { 'Content-Type': 'application/json' }
    })
    //return this.perform('get', `/api/files/get_presigned_url/?${qryStr}`, this.token)
  }

  async multiUploadtoS3(url, file) {
    // Default options are marked with *
    let callCount1 = 0;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      const { req, response: { status } } = error;
      const originalRequest = req;
      ++callCount1;
      if (status === 500 && callCount1 < 5) {
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve(originalRequest), 5000);
        });
      } else {
        callCount1 = 0;
        error.reject = true;
        let error1 = { reject: true, error: error }
        return Promise.reject(error1);
      }
    });
    return await axios.put(url, file, config);
  }
  getMultipartId(qryStr) {
    return this.perform('get', `/api/files/start_multipart_upload/?${qryStr}`, this.token)
  }
  sendMultipartUpdate(qryStr) {
    return this.perform('get', `/api/files/upload_part_copy/?${qryStr}`, this.token)
  }

  async uploadPresignedFile(url, file, onUploadProgres) {
    // Default options are marked with *
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: onUploadProgres };
    return await axios.post(url, file, config)
  }
  completeMultipartUpload(data) {
    return this.post('post', `/api/complete_multipart_upload/`, this.token, this.user, data);
  }
  clearMultipartbyId(qryStr) {
    return this.perform('get', `/api/files/abort_multipart_upload/?${qryStr}`, this.token);
  }
  uploadFile(user, file) {
    return this.post('post', `/api/files/`, this.token, user, file)
  }
  async getFileBinaryData(url, range) {
    let config = { responseType: 'blob' }
    if (range) {
      config['headers'] = { 'range': range };
      return await fetch(url, config);
    } else {
      return await axios.get(url, config);
    }
  }
  getCurrentUser() {
    return this.perform('get', '/api/current-user/', this.token)
  }
  search(data) {
    const query = { bucket: data['bucket'], key: data['key'], prefix: data['prefix']};
    let qryStr = queryString.stringify(query);
    return this.perform('get', `/api/search/?${qryStr}`, this.token);
  }
  fetch(data) {
    return this.perform('get', `/api/fetch/?bucket=${data}`, this.token)
  }
  copyFile(bucket, objKey, destination, size, bucket_region) {
    const query = { bucket: bucket, destination: destination, object_key: objKey, size: size, region: bucket_region};
    let qryStr = queryString.stringify(query);
    return this.perform('get', `/api/files/copy/?${qryStr}`, this.token);
  }
  deleteFile(bucket, key, bucket_region ) {
    const query = { bucket: bucket, object_key: key, region:bucket_region};
    let qryStr = queryString.stringify(query);
    return this.perform('get', `/api/files/delete_file/?${qryStr}`, this.token)
  }
  shareFile(bucket, objKey, data, bucket_region) {
    const query = { bucket: bucket, object_key: objKey, region: bucket_region};
    let qryStr = queryString.stringify(query);
    return this.post('post', `/api/files/share/?${qryStr}`, this.token, this.user, data);
  }
  async perform(method, resource, data) {
    if (data['token'] === null) {
      var token = this.token['token']
    } else {
      token = data['token']
    }

    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data"
      }
    }).then(resp => {
      return resp.data['results'] ? resp.data['results'] : resp.data;
    })
  }
  async getPaginatedApi(method, resource, data, newdata = []) {
    if (data['token'] === null) {
      var token = this.token['token']
    } else {
      token = data['token']
    }

    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      let compressedData = Uint8Array.from(atob(response.data), (c) => c.charCodeAt(0));
      let decompressedData = pako.inflate(compressedData, { to: "string" });
      let jsonObject = JSON.parse(decompressedData);
      return jsonObject;
    })
  }
  async createFolder(method, resource, accessToken, user, user_data, bucket, folder) {
    if (accessToken['token'] === null) {
      var token = this.token['token']
    } else {
      token = accessToken['token']
    }
    var data = {
      "name": user_data["name"],
      "owner": user.id,
      "parent": folder,
      "bucket": bucket,
      "active": true,
    }
    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(resp => {
      return resp.data['results'] ? resp.data['results'] : resp.data;
    })

  }
  async post(method, resource, accessToken, user, file) {
    if (accessToken['token'] === null) {
      var token = this.token['token']
    } else {
      token = accessToken['token']
    }

    return client({
      method,
      url: resource,
      data: file,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(resp => {
      return resp.data['results'] ? resp.data['results'] : resp.data;
    })

  }
}

export default APIClient;
