import React from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
const appConstants = {
    folderColumns: [
        {
            title: 'Name',
            field: 'Name',
            headerStyle: { fontWeight: 'bold' },
            cellStyle: {
                width: '30%',
                overfloWrap: 'anywhere'
            },
            render: rowData => <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: '5px' }}>
                    {rowData.is_folder ? <FolderIcon fontSize="small" /> : <DescriptionIcon fontSize="small" />}
                </span>
                <span>{rowData.Name}</span>
            </div>
        },
        {
            title: 'Last Modified',
            field: 'LastModified',
            editable: 'never',
            searchable: false,
            headerStyle: { fontWeight: 'bold' },
            customSort:  (a, b) => {
                a = new Date(a.LastModified).getTime();
                b = new Date(b.LastModified).getTime();
                   return b > a ? 1 : -1;
                }
        },
        {
            title: 'Storage Class',
            field: 'StorageClass',
            type: 'string',
            editable: 'never',
            searchable: false,
            headerStyle: { fontWeight: 'bold' }
        },
        {
            title: 'Size',
            field: 'ConvertedSize',
            type: 'string',
            editable: 'never',
            searchable: false,
            headerStyle: { fontWeight: 'bold' },
            customSort: (a, b) => parseInt(a.Size) - parseInt(b.Size)
        }
    ],
    restrictedAccessFolders: [{
        title: 'Name',
        field: 'Name',
        headerStyle: { fontWeight: 'bold' },
        render: rowData => <div style={{ display: 'flex', paddingRight: '5px' }}>
            <span style={{ paddingRight: '5px' }}><FolderIcon fontSize="small" /></span>
            <span>{rowData.Name}</span> </div>
    },

    {
        title: 'Created At',
        field: 'created_at',
        headerStyle: { fontWeight: 'bold' }

    }
    ],
    searchResultsFolders: [
        {
            title: 'Name',
            field: 'Name',
            cellStyle: {
                width: '30%',
                overfloWrap: 'anywhere'
            },
            headerStyle: { fontWeight: 'bold' },
            render: rowData => <div style={{ display: 'flex' }}><span style={{ paddingRight: '5px' }}>
                {rowData.is_folder ? <FolderIcon fontSize="small" /> : <DescriptionIcon fontSize="small" />}
            </span> <span>{rowData.Name}</span> </div>
        },
        {
            title: 'Last Modified',
            field: 'LastModified',
            editable: 'never',
            searchable: false,
            headerStyle: { fontWeight: 'bold' },
        },
        {
            title: 'Storage Class',
            field: 'StorageClass',
            type: 'string',
            editable: 'never',
            searchable: false,
            headerStyle: { fontWeight: 'bold' }
        },
        {
            title: 'Size',
            field: 'ConvertedSize',
            type: 'string',
            editable: 'never',
            searchable: false,
            headerStyle: { fontWeight: 'bold' },
            customSort: (a, b) => parseInt(a.Size) - parseInt(b.Size)
        }
    ]
};

export default appConstants;
