import React from 'react'
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom'
import { withAuth } from '@okta/okta-react';
import Placeholder from '../Component/Js/Loginplaceholder';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: null };
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.login = this.login.bind(this);
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });

    }
  }

  async componentDidMount() {
    this.checkAuthentication()

  }

  async login(e) {
    this.props.auth.login('/app');
  }

  render() {
    if (this.state.authenticated) {
      return <Redirect to='/app' />
    } else {
      return this.login()
    }
  }
}

export default withAuth(Login);
