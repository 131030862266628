import React from 'react';
import { createRoot } from 'react-dom/client';
import Main from './Main';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';
import Login from './Common/Login';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const container = document.getElementById('root');
const root = createRoot(container);
root.render((
  <div>
    <Router history={history} >
      <Security
        issuer={process.env.REACT_APP_ISSUER}
        client_id={process.env.REACT_APP_CLIENT_ID}
        redirect_uri={process.env.REACT_APP_REDIRECT_URL}
        scope={['openid', 'profile', 'email']}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/implicit/callback" component={ImplicitCallback} />
          <SecureRoute path="/app" component={Main} />
          <Route path="**" ><Redirect to='/' /></Route>
        </Switch>
      </Security>
    </Router >
  </div>

))
