import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { ReactComponent as LoadingSVG } from '../Assets/loading.svg';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import '../Css/copyFile.css';


export default function Copyfile(props) {
    const [treeData, setTreeData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [disableButton, setDisableButton] = useState(true);
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [destination, setDestination] = useState();
    const [renderSource, setRenderSource] = useState('');
    const [pasteStatus, setPasteStatus] = useState([]);
    const [pasteStatusRender, setPasteStatusRender] = useState();
    async function initializeData() {
        props.api.pasteStatus = [];
        props.api.getBucket().then(res => {
            let data = [];
            res.map((x, i) => {
                data.push({
                    key: `${i}`,
                    label: x.alias ? x.alias : x.name,
                    id: x.id,
                    bucketName: x.name,
                    leaf: false,
                    bucket: true,
                    full_access: x.full_access,
                    read_only: x.user_permission === "FullAccess" ? false : true,
                });
            });
            setTreeData(data);
        });
    }
    useEffect(() => {
        setTimeout(() => initializeData(), 300);
        return () => {
        };
    }, [])
    useEffect(() => {
        if (props.copyData.length && props.orgBucketName) {

            let render = props.copyData.map((x, i) => {
                return <Chip
                    className="chip"
                    key={x.Key + i}
                    label={props.orgBucketName + '/' + x.Key}
                    color="primary"
                    variant="outlined"
                    title={props.orgBucketName + '/' + x.Key}
                />
                //return <span>{props.orgBucketName + '/' + x.Key}</span>
            });
            setRenderSource(render);
        }
        return () => {
        };
    }, [props.copyData, props.orgBucketName])
    useEffect(() => {
        if (pasteStatus.length > 0) {
            let _cellRender = pasteStatus.map((x, i) => {
                return <div className="status-row">
                    <div className="status-cell width-250" title={props.orgBucketName + '/' + x.Key}>
                        {x.Name}
                    </div>
                    <div className="status-cell">
                        {x.status === 'progress' ? <LoadingSVG title="In Progress" /> : x.status === 'Failed' ?
                            <ErrorIcon style={{ color: '#e85445' }} title="Failed" /> : <CheckCircleIcon style={{ color: '#42d662' }} title="Completed" />}
                    </div>
                </div>
            });
            let render = <div className="status-main">
                <div className="status-table">
                    {_cellRender}
                </div>

            </div>
            setPasteStatusRender(render);
        }
    }, [pasteStatus])
    async function handleClick(event) {
        if (!event.node.children) {
            setLoading(true);
            setTimeout(async () => {
                let node = { ...event.node };
                let folderName = node.bucket ? ' ' : node.path;
                let bucketName = node.bucketName;
                if (!node.full_access && node.bucket) {
                    props.api.getBucket(node.id).then(data => {
                        let folders = data;
                        node.children = [];
                        folders.map((x, i) => {
                            node.children.push({
                                key: node.key + '-' + i,
                                full_access: node.full_access,
                                read_only: !(x.user_permission === "FullAccess"),
                                label: x.name,
                                path: x.name + '/',
                                id: x.Key,
                                bucket: false,
                                leaf: false,
                                bucketName: node.bucketName
                            });
                        });
                        let value = [...treeData];
                        value[parseInt(event.node.key, 10)] = node;
                        setTreeData(value);
                        setLoading(false);
                    });
                } else {
                    props.api.getFolderNew(folderName, bucketName, '', true).then(
                        (data) => {
                            let folders = data.objects;
                            if (folders.length > 0) {
                                node.children = [];
                                folders.map((x, i) => {
                                    node.children.push({
                                        key: node.key + '-' + i,
                                        full_access: node.full_access,
                                        read_only: node.read_only,
                                        label: x.Name,
                                        path: x.Key,
                                        id: x.Key,
                                        bucket: false,
                                        leaf: false,
                                        bucketName: node.bucketName
                                    });
                                });
                                setTreeDataMultiLevel(event, node);
                            } else {
                                node.leaf = true;
                                setTreeDataMultiLevel(event, node);
                            }

                        }
                    );
                }
            }, 500);
        }
    };
    function handleSelectChange(event) {
        setSelectedFile(event.value);
        if (event.value) setDisableButton(false);
        else setDisableButton(true);
        destinationPath(event.value);
    }
    function handleSelect(event) {
        //setTimeout(() => {
        if (event.node.read_only) {
            handleSelectChange({});
        } else if (event.node.key === selectedFile) {
            handleSelectChange({});
        } else {
            handleSelectChange({ value: (event.node.key) });
        }
        //}, 10);
    }
    function recursive(parent, index1, node) {
        if (!index1.length) {
            //value = node;
            return node;
        } else {
            let i = index1.shift();
            parent.children[i] = recursive(parent.children[i], index1, node);
        }
        return parent;
    }
    function setTreeDataMultiLevel(event, node) {
        let value = { children: [...treeData] };
        let index = event.node.key.split('-');
        if (index.length === 1) {
            value.children[parseInt(event.node.key, 10)] = node;
        } else {
            let indexes = Object.assign([], index);
            let parent1 = recursive(value, indexes, node);
        }
        setTreeData(value.children);
        setLoading(false);
    }
    function destinationPath(selectedObj) {
        if (selectedObj) {
            let index = selectedObj.split('-');
            let data = treeData[index.shift()];
            while (index.length) {
                data = data.children[index.shift()];
            }
            let destination = `${data.bucketName}/${data.bucket ? `` : data.path}`;
            setDestination(destination);
        } else {
            setDestination();
        }
    }
    function statusPostPast(all, data, index, selection, status) {
        if (all) {
            props.api.pasteStatus = [];
            data.map((x, i) => {
                props.api.pasteStatus.push({ Name: x.Name, status: status, Key: x.Key })
            });
        } else {
            let array = Object.assign([], props.api.pasteStatus);
            // let index = array.findIndex(x => x.Name === selection.Name);
            array.splice(index, 1, { Name: selection.Name, status: status, Key: selection.Key });
            props.api.pasteStatus = Object.assign([], array);
        }
        setPasteStatus(props.api.pasteStatus);
    }
    function sequentialCopyCall(data, _destination, count) {
        return props.api.copyFile(props.orgBucketName, data.Key, _destination, data.Size, props.api.region).then(result => {
            if (result[200] === "OK") {
                // props.sendTostMessage(`${data.Name} pasted to ${destination} successfully.`, 'success');
                statusPostPast(false, props.copyData, count, data, 'Complete');
                return count++;
            } else if (result.result === "Copy Started") {
                props.sendTostMessage(`${data.Name} copy to ${destination} has been intiated, You will be notified through email once paste operation is complete.`, 'success');
                statusPostPast(false, props.copyData, count, data, 'Complete');
                return count++;
            }
            else {
                props.sendTostMessage(`${data.Name} pasting to ${destination} Failed.`, 'error');
                statusPostPast(false, props.copyData, count, data, 'Failed');
                setShowBackDrop(false);
                return count++;
            }
            /* if (count === props.copyData.length) {
                // props.copySuccessful();
            } */

        }).catch(error => {
            if (error.response.status === 403) {
                props.sendTostMessage('You are not authorized to do this operation, please check with your admin.', 'error');
            } else {
                statusPostPast(false, props.copyData, count, data, 'Failed');
                props.sendTostMessage('Copy operation failed , please try again.', 'error');
                return count++;
            }
            setShowBackDrop(false);
        });
    }
    async function pasteFile() {
        setShowBackDrop(true);
        let count = 0;
        statusPostPast(true, props.copyData, null, null, 'progress');
        // props.copyData.forEach(async (data) => {
        for (let i = 0; i < props.copyData.length; i++) {
            let data = props.copyData[i];
            let _destination;
            if (data.folderCopy)
                _destination = destination + data.folderName;
            else
                _destination = destination;

            let _res = await sequentialCopyCall(data, _destination, i);
            var res = await _res;
        }
    }

    return (
        <div>
            {!pasteStatusRender ? <div>
                <Backdrop className="backDrop" open={showBackDrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="note"><b>Note:</b> Copy object cannot be performed on readonly buckets/folders.</div>
                <div className="source">
                    <span className="source-main">Source Object : </span>{renderSource}
                </div>
                <div className="source">
                    <span className="source-main">Destination : </span>
                    {destination ? <Chip
                        className="chip"
                        label={destination}
                        color="primary"
                        variant="outlined"
                    /> : ''}
                </div>
                <Button className="printButton" label="Paste File" icon="pi pi-copy" disabled={disableButton} onClick={() => pasteFile()} />
                <Tree className="treeMain" value={treeData} selectionMode="single" selectionKeys={selectedFile} onExpand={handleClick}
                    loading={loading} onSelect={handleSelect} />
            </div> : pasteStatusRender}
        </div>
    );
}
