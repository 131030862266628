import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import '../Css/searchBar.css';
const styles = theme => ({
    root: {
        display: 'contents'
    }
});

function SearchBar(props) {

    const [searchVal, setSearchVal] = useState(props.searchVal ? props.searchVal : '');
    const [textFieldVal, settextFieldVal] = useState(props.textFieldVal ? props.textFieldVal : '');
    const [selectedRows, setSelectedRows] = useState([]);
    const [openFldr, setFldrOpen] = useState(false);
    useEffect(() => {
        if (props.searchVal || props.searchVal === "") {
            setSearchVal(props.searchVal)
        }
    }, [props.searchVal])
    useEffect(() => {
        // if (props.selectedRows.length) {
        setSelectedRows(props.selectedRows)
        // }
    }, [props.selectedRows])

    function handleKeyDown(e, target) {
        if (e.key === "Enter") {
            props.handleSearchEnter(e.target);
        }
    }
    function clearSearch() {
        let data = { value: '' };
        props.handleSearchEnter(data);
        setSearchVal('');
    }
    function handleChange(e) {
        setSearchVal(e.target.value);
        if (e.target.value === '') {
            props.handleSearchEnter({ value: '' });
        }
    }

    function handleInputChange(e) {
        settextFieldVal(e.target.value);
    }

    function handleBulkCopy(event) {
        props.handleBulkCopy(selectedRows);
    }
    function handleBulkDelete(event) {
        props.handleBulkDelete(selectedRows);
    }
    function handleBulkDownload(event) {
        props.handleBulkDownload(selectedRows);
    }
    function handleUpload(event) {
        props.handleUpload();
    }
    
    function setFldrOpenNew() {
       setFldrOpen(true);
    }

    function handleFldrClose() {
        setFldrOpen(false);
    }

    function handleFldrCreate(event) {
        console.log(event,textFieldVal);
        props.handleFldrCreate(textFieldVal);
        setFldrOpen(false);
    }

    return (
        <>
            <div className={styles.root}>
                <Dialog open={openFldr} onClose={handleFldrClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create Folder</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please give a Folder name
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="fldrName"
                            label="FolderName"
                            type="text"
                            defaultValue=""
                            onChange={handleInputChange} 
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFldrClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleFldrCreate} color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div className="root-align-ico">
                <div className="actionIco">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        hidden= {["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket_name) && props.user.toLowerCase() !== 'neha.kasat@aenetworks.com'}
                        disabled={!props.uploadAccess}
                        // disabled={
                        //     (props.bucket_name !== 'sactestbucket' && !props.uploadAccess) ||
                        //     (props.bucket_name === 'sactestbucket' && props.user !== 'Ksassali.Muhammad@aenetworks.com')
                        //   }
                        startIcon={<CreateNewFolderIcon />}
                        onClick={setFldrOpenNew}
                    >
                        New Folder
                    </Button>
                </div>
                <div className="actionIco">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!props.uploadAccess}
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUpload}
                    >
                        Upload
                    </Button>
                </div>
                <div className="actionIco">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        hidden= {["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket_name)}
                        disabled={!selectedRows.length}
                        startIcon={<CloudDownloadIcon />}
                        onClick={handleBulkDownload}
                    >
                        Download
                    </Button>
                </div>
                <div className="actionIco">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        hidden= {["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket_name)}
                        disabled={!selectedRows.length || !props.uploadAccess}
                        startIcon={<FileCopyIcon />}
                        onClick={handleBulkCopy}
                    >
                        Copy
                    </Button>
                </div>
                {props.deleteAccess ? <div className="actionIco">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!selectedRows.length || !props.uploadAccess}
                        startIcon={<DeleteIcon />}
                        onClick={handleBulkDelete}
                    >
                        Delete
                    </Button>
                </div> : ''}
            </div>
            <div className="MuiFormControl-root MuiTextField-root MTableToolbar-searchField-220 root-align">
                <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd">
                    <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="Search">
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 
                        4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                        </svg>
                    </div>
                    <input aria-invalid="false" placeholder="Search" type="text" onKeyDown={handleKeyDown} onChange={handleChange} value={searchVal}
                        className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd" />
                    <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                        <button className="MuiButtonBase-root MuiIconButton-root " tabIndex="0" onClick={clearSearch} type="button" >
                            <span className="MuiIconButton-label">
                                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" >
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                    </path>
                                </svg>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

SearchBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchBar);