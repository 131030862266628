import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { ReactComponent as BucketSvg } from './bucket.svg';
import queryString from 'query-string'

import '../../App.css';
const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 30
  }
});

class Home extends React.Component {
  state = {
    value: 0,
    repos: [],
    token: [],
    envs: [],
    buckets: [],
    listOfAccounts: [],
    dataLoading: true,
  };
  async componentDidMount() {
    const { id } = await this.props.match.params
    const response = await this.props.api.getBucket(id);
    var json = await response;
    for (var i = 0; i < json.length; i++) {
      if (json[i].alias) {
        json[i].name = json[i].alias
      }
    }
    this.setState({
      buckets: json,
      title: 'Buckets',
      dataLoading: false,
      columns: [
        {
          title: 'Bucket Name',
          field: 'name',
          render: rowData => <div style={{ display: 'flex' }}><span style={{ paddingRight: '5px' }}><BucketSvg /></span> <span>{rowData.name}</span> </div>,
          headerStyle: { fontWeight: 'bold' }
        }
      ],
    });

  }


  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleTabChangeIndex = index => {
    this.setState({ value: index });
  };
  handleRowClicked(event, rowData) {
    this.props.api.fullaccess = rowData.full_access;
    this.props.api.pageSize = 10;
    let flag;
    flag = rowData.user_permission === 'FullAccess' ? true : false;
    this.props.api.folderAccess = flag;
    this.props.api.folderDelete = rowData.enable_delete;
    const query = { bucketid: rowData.id, bucket: rowData.name, folder: [], DA: this.props.api.folderAccess };
    let qryStr = queryString.stringify(query);
    if (rowData.full_access) {
      this.props.history.push(`/app/folder?${qryStr}`)
    }
    else {
      this.props.history.push(`/app/folder?${qryStr}&FA=0`)
    }
  }
  render() {
    return (
      <div className={styles.root}>
        <div style={{ 'marginBlockEnd': '0.5em' }} />
        <MaterialTable
          options={
            {
              actionsColumnIndex: -1,
              pageSize: 10
            }
          }
          onRowClick={
            (event, rowData) => this.handleRowClicked(event, rowData)
          }
          icons={
            tableIcons
          }
          title={this.state.title}
          columns={
            this.state.columns
          }
          data={
            this.state.buckets
          }
          isLoading={
            this.state.dataLoading
          }

        />
      </div>
    );
  }
}

export default withStyles(styles)(withAuth(Home));
