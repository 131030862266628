import React, { useState, useEffect } from 'react';
import { withAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
// import SearchBar from 'material-ui-search-bar'
import Notification from './Notifications';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import fileboxLogo from '../Assets/filebox-logo.png';
import Feedback from './Feedback';

const styles = theme => ({
  root: {
    width: '100%',
  },
  logo: {
    width: 95,
    marginLeft: -10,
    cursor: 'pointer'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: '#89cdff'
  },
  Toolbar: {
    minHeight: 54
  },
  title: {
    display: 'none',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {

    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  chip: {
    margin: 'auto',
    paddingRight: 5,
    backgroundColor: '#000'
  },

});

function TopBar(props) {

  const [anchorEl, setAnchorEl] = useState(null);
  const [mainAnchorEl, setMainAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [isMenuOpen, setisMenuOpen] = useState(Boolean(anchorEl));
  const [isMainMenuOpen, setisMainMenuOpen] = useState(Boolean(mainAnchorEl));
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(Boolean(mobileMoreAnchorEl));
  const [classes, setclasses] = useState(props.classes);
  const [renderProfileMenu, setRenderProfileMenu] = useState(<MenuItem key={Math.random()} onClick={logout}>Logout</MenuItem>);
  const [renderMainMenu, setRenderMainMenu] = useState(props.data.map((x, i) => {
    if (i === 0) return
    return <MenuItem key={x.key} onClick={() => props.history.push(x.path)}>{x.display}</MenuItem>
  }));
  const [topbarData, setTopBarData] = useState({ path: '/app', key: 'filebox', display: 'FileBox' });
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
      getUserDetails();
    }
    return () => {
    };
  }, [props.user])
  useEffect(() => {
    setisMenuOpen(Boolean(anchorEl))
    return () => {
    };
  }, [anchorEl])
  useEffect(() => {
    setisMainMenuOpen(Boolean(mainAnchorEl))
    return () => {
    };
  }, [mainAnchorEl])

  useEffect(() => {
    setisMobileMenuOpen(Boolean(mobileMoreAnchorEl))
    return () => {
    };
  }, [mobileMoreAnchorEl])
  useEffect(() => {
    if (props.data.length > 0) {
      setTopBarData(props.data[0]);
      setRenderMainMenu(props.data.map((x, i) => {
        if (i === 0) return
        return <MenuItem key={x.key} onClick={() => { handleMainMenuClose(); props.history.push(x.path); }}>{x.display}</MenuItem>
      }));
    }
  }, [props.data])
  function setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (2 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=Bearer " + cvalue + ";" + expires + ";domain=.filebox.aenetworks.com;Secure;SameSite=None";   //";path=/;Secure"
  }
  function toAdminPage() {
    handleMenuClose();
    // setCookie('fbxtoken', props.api.token.token )
    window.open(process.env.REACT_APP_API_URL + "/api/admin_login?fbxtoken=" + props.api.token.token);
  }
  function getUserDetails() {
    const currUser = props.api.getCurrentUser();
    //const user = await currUser;
    currUser.then((user) => {
      if (user.is_staff) {
        setRenderProfileMenu(<><MenuItem key={Math.random()} onClick={toAdminPage}>Admin</MenuItem>
          <MenuItem key={Math.random()} onClick={logout}>Logout</MenuItem>
        </>);
      }
    });
  }
  async function logout() {
    // Redirect to '/' after logout
    props.auth.logout('/');
  }
  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  function handleMainMenuOpen(event) {
    setMainAnchorEl(event.currentTarget);
  };

  function handleMainMenuClose() {
    setMainAnchorEl(null);
    // handleMobileMenuClose();
  };

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const mainopen = Boolean(mainAnchorEl);
  const id = open ? 'simple-popover' : undefined;
  const mainid = mainopen ? 'simple-popover' : undefined;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.Toolbar}>
          <IconButton /* onClick={handleMainMenuOpen} */ className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton>
          {topbarData.key = "filebox" ? <img src={fileboxLogo} onClick={() => props.history.push(topbarData.path)} alt="logo" className={classes.logo} /> :
            <Typography onClick={() => props.history.push(topbarData.path)} className={classes.title} variant="h6" color="inherit" noWrap>
              {topbarData.display}
            </Typography>
          }


          {/* <SearchBar
              value={this.state.value}
              onChange={(newValue) => this.setState({ value: newValue })}
              onRequestSearch = {this.handleSearchBucket.bind(this)
              }
            /> */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton> */}
            <Feedback {...props} />
            {user ? <Notification {...props} /> : ''}
            <Chip
              className={classes.chip}
              icon={<AccountCircle />}
              label={props.api.user.name}
              onClick={handleProfileMenuOpen}
              clickable
              color="primary"
            />
            {/* <IconButton
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
              <Typography onClick={() => props.history.push(topbarData.path)} className={classes.title} variant="h6" color="inherit" noWrap>
                {topbarData.display}
              </Typography>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Popover id={id}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
        <Paper>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <MenuList key={Math.random()} autoFocusItem={isMenuOpen} id="menu-list-grow">
              {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
              <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
              {renderProfileMenu}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
      <Popover id={mainid} open={isMainMenuOpen} anchorEl={mainAnchorEl} onClose={handleMainMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
        <Paper>
          <ClickAwayListener onClickAway={handleMainMenuClose}>
            <MenuList key={Math.random()} autoFocusItem={isMainMenuOpen} id="menu-list-grow" /* onKeyDown={handleListKeyDown} */>
              {renderMainMenu}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
      {/* <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu> */}
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMenuClose}
      >

        {/* <MenuItem onClick={handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem> */}
        <MenuItem key={Math.random()} onClick={handleMobileMenuClose}>
          <IconButton color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem key={Math.random()} onClick={handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>{props.api.user.name}</p>
        </MenuItem>
      </Menu>
    </div>
  );
}

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuth(TopBar));