import React, { useState, useEffect } from 'react';
import FeedbackIcon from '@material-ui/icons/Feedback';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import '../Css/feedback.css';
const useStyles = makeStyles(theme => ({
    feedback: {
        marginRight: 5,
        marginTop: 5
    },
}));

export default function Feedback(props) {
    const [modelOpen, setModelOpen] = useState(false);
    const [feedbackType, setFeedbackType] = useState('Comments');
    const [feedbackDesc, setFeedbackDesc] = useState('');
    const [feedbackClick, setFeedbackClick] = useState(false);
    const [openAlert, setOpenAlert] = useState();
    const classes = useStyles();

    function handleClick(event) {
        setModelOpen(true);
    }
    function handleModelClose() {
        setModelOpen(false);
    }
    const handleChange = (event) => {
        setFeedbackType(event.target.value);
    }
    const handleDescChange = (event) => {
        setFeedbackDesc(event.target.value);
    };
    const submitFeedback = (event) => {
        setFeedbackClick(true);
        let data = {
            "feedback_type": feedbackType,
            "feedback": feedbackDesc
        };
        props.api.setFeedback(data).then(res => {
            setFeedbackClick(false);
            setFeedbackDesc("");
            setOpenAlert('Feedback submitted successfully.');
        });
    }
    return (
        <div>
            {modelOpen ? <Modal
                open={modelOpen}
                onClose={handleModelClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                disableBackdropClick={false}
                disableEscapeKeyDown={false}
            >
                {modelOpen ? <div className="feedback-modelMain">
                    <div>
                        <Collapse className="alertMain" in={!openAlert === false}>
                            <Alert onClose={() => { setOpenAlert() }} severity="success">
                                {openAlert}
                            </Alert>
                        </Collapse>
                    </div>
                    <div className="feedback-main">
                        <div className="feedback-header">Feedback Form</div>
                        <div className="feedback-subhead">We would love to hear your thoughts, concerns or problems with anything so we can improve!</div>
                        <hr></hr>
                        {/* <div className="feedback-type">Feedback Type</div> */}
                        <div className="feedback-options">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Feedback Type</FormLabel>
                                <RadioGroup row aria-label="feedbacktype" name="feedbacktype1" value={feedbackType} onChange={handleChange}>
                                    <FormControlLabel value="Comments" control={<Radio color="primary" />} label="Comments" />
                                    <FormControlLabel value="BugReports" control={<Radio color="primary" />} label="Bug Reports" />
                                    <FormControlLabel value="Questions" control={<Radio color="primary" />} label="Questions" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="feedback-description">
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Describe Feedback"
                                required
                                fullWidth
                                multiline
                                value={feedbackDesc}
                                error={(feedbackClick && !feedbackDesc.length > 0)}
                                rows={4}
                                rowsMax={6}
                                onChange={handleDescChange}
                                variant="outlined"
                            />
                        </div>
                        <div className="feedback-submit">
                            <Button variant="outlined" color="primary" onClick={submitFeedback}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
                    : ''}

            </Modal> : ''}
            <IconButton className={classes.feedback} color="inherit" onClick={handleClick}>
                <Badge color="primary">
                    <FeedbackIcon />
                </Badge>
            </IconButton>
        </div>
    );
}
