import React, { useState, useEffect } from 'react';
import { withAuth } from '@okta/okta-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useRouteMatch } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import TopBar from '../Common/Component/Js/TopBar';
import SimpleBreadcrumbs from "../Common/Component/Js/Breadcrumbs";
import Home from '../Filebox/Home'
import S3File from '../Filebox/File'
import APIClient from '../APIService';
import Placeholder from '../Common/Component/Js/Loginplaceholder';
import util from '../Common/Assets/util';

toast.configure();
async function getUser(auth) {
  return await auth.getUser();
}

function Main(props) {
  const [coldStartFlag, setcoldStartFlag] = useState(false);
  setTimeout(() => {setcoldStartFlag(true);}, 15000)
  // let folderview = useRouteMatch('/app/folder');
  const [user, setUser] = useState();
  const [apiServ, setApiServ] = useState();
  const matchFTP = useRouteMatch({
    path: '/app/FTP', strict: true, sensitive: true
  })
  let topbarData = [
    { path: '/app', key: 'filebox', display: 'FileBox' }, { path: '/app/FTP', key: 'ftp', display: 'FTP' }
  ];
  if (matchFTP)
    topbarData = [
      { path: '/app/FTP', key: 'ftp', display: 'FTP' }, { path: '/app', key: 'filebox', display: 'FileBox' }
    ];

  useEffect(() => {
    getUser(props.auth).then((res) => {
      setApiServ(new APIClient(res));
      //setUser(res);
    });
  }, []);
  useEffect(() => {
    if (apiServ) {
      apiServ.userAuth().then((res) => {
        apiServ.token = res;
        // document.cookie = "fbxtoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setUser(apiServ.user);
      });
    }
  }, [apiServ]);
  util.fullAccess$.subscribe(val => {
    if (apiServ) {
      if (!apiServ.changeInitiated) {
        apiServ.userAuth().then((res) => {
          apiServ.token = res;
          // document.cookie = "fbxtoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          toast.error('It seems that your token has expired,We have updated your token. Please retry your previous operation.', {
            position: toast.POSITION.TOP_CENTER
          });
        });
      }
      apiServ.changeInitiated = true;
    }
  });
  return (
    <>
      {user ? <TopBar {...props} api={apiServ} data={topbarData} user={user} /> : ''}
      {user && !matchFTP ? <SimpleBreadcrumbs {...props} /> : ''}
      {user ?
        <Switch>
          <SecureRoute exact path={props.match.url + "/"}
            render={() => <Home {...props} api={apiServ} />} />
          <SecureRoute path={props.match.url + "/folder"}
            render={() => <S3File {...props} api={apiServ} toast={toast} />} />
          <Route path="**" ><Redirect to={props.match.url + "/"} /></Route>
        </Switch>

        : < Placeholder loader={ true } coldStart={coldStartFlag} />
      }
    </>
  );
}

export default withAuth(Main);