import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import fileboxLogo from '../Assets/filebox-logo1.png';
import reloadIcon from '../Assets/reloadIcon.png';

const styles = theme => ({
    root: {
        display: 'contents'
    },
    root1: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        width: 350,
        marginTop: -160,
        marginBottom: -100
    },
    loader: {
        color: '#f1705c'
    },
    reloadStyle:{
        width: 38,
        marginTop: 10

    }
});

function handleRender() {
    window.location.reload();
}
function Placeholder(props) {
    const [classes, setclasses] = useState(props.classes);

    return (
        <div className={props.loader ? classes.root1 : classes.root}>
            <img src={fileboxLogo} alt="logo" className={classes.logo} />
            {props.loader ? <CircularProgress className={classes.loader} /> : ''}
            {props.coldStart ? <span>Please refresh the page on Coldstart.</span> : ''}
            {props.coldStart ? <img onClick={ handleRender } src={reloadIcon} alt="logo" className={classes.reloadStyle} style={{cursor:'pointer'}} /> : ''}
        </div >
    );
}

Placeholder.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Placeholder);
