import React, { useEffect, useState } from 'react';
import '../Css/breadcrumb.css';
import { makeStyles } from '@material-ui/core/styles';
import Home from '@material-ui/icons/Home';
import SettingsSystemDaydream from '@material-ui/icons/SettingsSystemDaydream';
import Folder from '@material-ui/icons/Folder';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles(theme => ({
  root: {
    width: 360,
    maxHeight: 300,
    backgroundColor: theme.palette.background.paper,
  },
  chipNone: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    border: 'none',
    height: 'auto',
    background: 'none',
    color: '#fff',
    cursor: 'inherit'
  }
}));
export default function SimpleBreadcrumbs(props) {
  let breadcrumbData = [{ key: 'Home', type: 'Home', path: '/app' }]
  let location = useLocation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [showBreadcrumb, setShowBreadcrumb] = useState(true);
  const [rendValue, setRendValue] = useState();

  useEffect(() => {
    let paramValues = queryString.parse(location.search);
    let otherData = [];
    (paramValues.bucket) && otherData.push({ key: paramValues.bucket, type: 'Bucket', path: `/app/folder?bucketid=${paramValues.bucketid}&bucket=${paramValues.bucket}&FA=${paramValues.FA ? paramValues.FA : 1}${paramValues.DA ? `&DA=${paramValues.DA}` : ``}` });
    if (paramValues.folder) {
      let folders = paramValues.folder.split('/');
      folders.map((x, i) => {
        otherData.push({
          key: x, type: 'Folder', path: `/app/folder?bucketid=${paramValues.bucketid}&bucket=${paramValues.bucket}&folder=${folders.slice(0, i + 1).join('%2F')}&FA=${paramValues.FA ? paramValues.FA : 1}${paramValues.DA ? `&DA=${paramValues.DA}` : ``}`
        });
      })
    }
    setData([...breadcrumbData, ...otherData]);
  }, [useLocation().search]);

  useEffect(() => {
    let renderVal = data.map(function (x) {
      return <li key={x.key}>
        <a onClick={() => { props.history.push(x.path) }}><Chip
          className={classes.chipNone}
          icon={chooseIcons(x.type)}
          label={x.key}
        /></a>
      </li>
    });
    setRendValue(renderVal);
  }, [data])
  function chooseIcons(type) {
    switch (type) {
      case 'Home':
        return <Home fontSize="small" style={{ color: '#89cdff' }} />
        break;
      case 'Bucket':
        return <SettingsSystemDaydream fontSize="small" style={{ color: '#89cdff' }} />
        break;
      case 'Folder':
        return <Folder fontSize="small" style={{ color: '#89cdff' }} />
        break;
      default:
        return ''
    }
  }
  return (
    <>
      {showBreadcrumb && <ul className="breadcrumb1">
        {rendValue}
      </ul>}
    </>
  );
}