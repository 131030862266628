import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import queryString from 'query-string';
// import LinearProgress from '@material-ui/core/LinearProgress';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/dragDrop.css';
import $ from 'jquery';
import ClearIcon from '@material-ui/icons/Clear';
import ReplayIcon from '@material-ui/icons/Replay';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DragDrop(props) {
    const [api, setApi] = useState(props.api);
    const [percentCompleted, setPercentCompleted] = useState([]);
    const [uploadFilesList, setUploadFilesList] = useState([]);
    const partSize = 20 * 1024 * 1024;
    const [disable, setDisable] = useState(false);
    const [openAlert, setOpenAlert] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [multipartDetails, setMultipartDetails] = useState([]);
    const [renderProgressBar, setRenderProgressBar] = useState();
    const [failedFilePath, setFailedFilePath] = useState();
    const [seriesUploaded, setSeriesUploaded] = useState(false);
    props.api.cancelMultipartUpload = false;
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            let progPercent = [];
            let _multipartDetails = [];
            let _fileUploadIdDetails = [];
            acceptedFiles.map((x, i) => {
                x.filePath = x.path.replace('/', '');
                progPercent.push({ path: x.filePath, status: 'in-progress', size: x.size, percent: 0, file: x });
                let fileCount = 0;
                if (x.size > partSize) {
                    _multipartDetails.push({
                        bucket: props.bucket,
                        folderName: props.folderName,
                        fileName: x.filePath
                    })
                    
                    getMultipartId(props.bucket, props.folderName, x.filePath, props.api.region).then(data => {
                        _fileUploadIdDetails.push({ 
                            bucket: props.bucket,
                            folderName: props.folderName,
                            fileName: x.filePath,
                            UploadId: data.UploadId,
                            Parts: data.Parts
                        });
                        props.api.uploadFilesList = Object.assign([], _fileUploadIdDetails);
                        setUploadFilesList(props.api.uploadFilesList);
                        if(acceptedFiles.length == props.api.uploadFilesList.length) {
                            seriesUpload(acceptedFiles, fileCount, props.api.uploadFilesList);
                        }
                    }).catch((error) => {
                        resetFieldsonError(error, acceptedFiles[i], i);
                        if (acceptedFiles) {
                            let count = i + 1;
                            seriesUpload(acceptedFiles, count, props.api.uploadFilesList)
                        }
                    });
                }
                else {
                    _multipartDetails.push(false);
                    _fileUploadIdDetails.push({ 
                        bucket: props.bucket,
                        folderName: props.folderName,
                        fileName: x.filePath
                    });
                    props.api.uploadFilesList = Object.assign([], _fileUploadIdDetails);
                    setUploadFilesList(props.api.uploadFilesList);
                    if(acceptedFiles.length == props.api.uploadFilesList.length) {
                        seriesUpload(acceptedFiles, fileCount, props.api.uploadFilesList);
                    }
                }

            });
            props.api.percentCompleted = Object.assign([], progPercent);
            props.api.multipartDetails = Object.assign([], _multipartDetails);
            setPercentCompleted(props.api.percentCompleted);
            setMultipartDetails(props.api.multipartDetails);
            setDisable(true);
            // var files_name = []
            let fileCount = 0;
            //seriesUpload(acceptedFiles, fileCount, fileUploadIdDetails);
            /* for (let i = 0; i < acceptedFiles.length; i++) {
                files_name.push(acceptedFiles[i]['path'])
                startUploadFile(acceptedFiles[i], i);
            } */
        }
        else {
            let error = {}
            error['message'] = "Please upload one file at a time.";
            props.sendTostMessage(`Please upload one file at a time.`, 'error');
        }
    }, [props.folderName])
    useEffect(() => {
        props.api.percentCompleted = [];
        props.api.multipartDetails = [];
    }, [])
    useEffect(() => {
        let render = percentCompleted.map((x, i) => {
            return <div className="upload">
                <span className="upload-name">{x.path + ' (' + formatBytes(x.size) + ') | Status : ' + x.status}</span>
                <div className="progressBar">
                    <ProgressBar variant={(x.status === 'Completed' || x.status === 'in-progress') ?
                        'success' : (x.status === 'Cancelled') ? 'warning' : 'danger'} now={x.percent} label={`${x.percent}%`} />
                    {(multipartDetails[i] && x.percent > 0) ? <ClearIcon className="button-pointer" onClick={e => { handleCancelMultipart(e, x, multipartDetails[i], i) }} /> : ''}
                    {x.file.failed ? <ReplayIcon className="button-pointer" onClick={e => { retryUploadFile(e, x, multipartDetails[i], i) }} /> : ''}
                </div>
            </div>
        });
        setRenderProgressBar(render);
    }, [percentCompleted, multipartDetails])
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
        disabled: disable
    });

    function divideFileToChunks(file) {
        var blobs = [];
        var start = 0;
        var end;
        var partNum = 0;
        let filePart;

        while (start < file.size) {
            end = Math.min(start + partSize, file.size);
            filePart = file.slice(start, end);
            // this is to prevent push blob with 0Kb
            if (filePart.size > 0)
                blobs.push(filePart);
            start = partSize * ++partNum;
        }
        return blobs;
    }
    function resetProgress() {
        props.api.percentCompleted = [];
        props.api.multipartDetails = [];
        setPercentCompleted(props.api.percentCompleted);
        setMultipartDetails(props.api.multipartDetails);

    }
    function setProgress(file, index, percent) {
        let _status = 'in-progress';
        if (parseInt(percent) === 100)
            _status = 'Completed';
        if (file.canceled)
            _status = 'Cancelled';
        if (file.failed)
            _status = 'Failed';
        let data = { path: file.filePath, status: _status, size: file.size, percent: percent ? percent : props.api.percentCompleted[index].percent, file: file }
        let array = Object.assign([], props.api.percentCompleted);
        array.splice(index, 1, data)
        props.api.percentCompleted = Object.assign([], array);
        setPercentCompleted(props.api.percentCompleted);
    }
    function setMultipartDetailsArray(index, data) {
        let array = Object.assign([], props.api.multipartDetails);
        array.splice(index, 1, data)
        props.api.multipartDetails = Object.assign([], array);
        setMultipartDetails(props.api.multipartDetails);
    }
    function retryUploadFile(event, data, multipartDetails, index) {
        data.file.failed = false;
        let _fileUploadIdDetails = [];
        _fileUploadIdDetails.push({ 
            bucket: multipartDetails.bucket,
            folderName: multipartDetails.folderName,
            fileName: data.path,
            UploadId: multipartDetails.partitionDetails.multiPartId,
            Parts: multipartDetails.partitionDetails.details
        });
        props.api.uploadFilesList = Object.assign([], _fileUploadIdDetails);
        setUploadFilesList(props.api.uploadFilesList);
        startUploadFile(null, data.file, index, 'No', props.api.uploadFilesList)
    }
    function seriesUpload(files, index, fileUploadIdDetails) {
        if (index === files.length) {
            setSeriesUploaded(true);
            props.refreshData(null, null, null);
            $(window).unbind('beforeunload')
            props.sendTostMessage(`All Files Uploaded successfully.`, 'success');
        } else {
            setSeriesUploaded(false);
            startUploadFile(files, files[index], index, 'No', fileUploadIdDetails);
        }
    }
    function startUploadFile(files, file, index, confirmRewrite, uploadIdDetails) {
        setTimeout(() => {
            // const file = acceptedFiles[0];
            if (file.size <= partSize) {
                getPresignedURL(props.bucket, props.folderName, file.filePath, props.api.region, confirmRewrite).then((data, success) => {
                    if(data["200"] == "duplicatefile") {
                        setOpenConfirm(true);
                        setFailedFilePath(file);
                        //props.sendTostMessage(`File/Folder with same already exists, update name and upload.`, 'error');
                        //file['failed'] = true;
                        //setProgress(file, index, null);
                        //setSeriesUploaded(true);
                    } else {
                        uploadtoS3(data, file, false, index).then((success) => {
                            setProgress(file, index, 100);
                            if (files) {
                                let count = index + 1;
                                seriesUpload(files, count, [])
                            }
                        }).catch((error) => {
                            // props.sendTostMessage(`failed to Upoad ${file.filePath}`, 'error');
                            file['failed'] = true;
                            setProgress(file, index, null);
                            if (files) {
                                let count = index + 1;
                                seriesUpload(files, count, [])
                            }
                        })
                    }
                }).catch((error) => {
                    // props.sendTostMessage(`failed to Upoad ${file.filePath}`, 'error');
                    file['failed'] = true;
                    setProgress(file, index, null);
                    if (files) {
                        let count = index + 1;
                        seriesUpload(files, count, [])
                    }
                })
            } else {
                api.fileSize = file.size;
                let partitionDetails = { multiPartId: 0, details: [] };
                let uploadedParts = 0;
                let batchSize = 100;
                props.api.cancelMultipartUpload = false;
                setOpenAlert('Do not Refresh page and avoid navigating to other bucket while upload is in progress.');
                $(window).bind('beforeunload', function (ev) {
                    //ev.preventDefault();
                    let confirmationMessage = "Your file upload progress will be saved even if you leave this page. If you want to cancel file upload please press the cancel button beside progress bar.";
                    ev.returnValue = confirmationMessage;
                    return confirmationMessage;
                });
                let fileUploadIdDetails;
                for (let i = 0; i < uploadIdDetails.length; i++) {
                    if(file.filePath == uploadIdDetails[i].fileName) {
                        fileUploadIdDetails = uploadIdDetails[i];
                        uploadIdDetails.splice(i,1);
                    }
                }
                
                partitionDetails.multiPartId = fileUploadIdDetails.UploadId;
                let _multipartDetails = {
                    bucket: fileUploadIdDetails.bucket,
                    folderName: fileUploadIdDetails.folderName,
                    fileName: fileUploadIdDetails.fileName,
                    partitionDetails: partitionDetails,
                    region:props.api.region
                }
                // props.api.multipartDetails[index] = _multipartDetails;
                setMultipartDetailsArray(index, _multipartDetails);
                // setMultipartDetails(_multipartDetails);
                let partitions = divideFileToChunks(file);
                setProgress(file, index, ((1 / partitions.length) * 100).toFixed(2));
                if (fileUploadIdDetails.Parts) {
                    fileUploadIdDetails.Parts.map((x, i) => {
                        let index = (i + 1);
                        partitionDetails.details[index] = { partitionName: file.name.split('.')[0] + '_' + index + '.txt', partitionId: Math.floor(index) };
                        partitionDetails.details[index]['E-Tag'] = x.ETag;
                    })
                    if (fileUploadIdDetails.Parts.length === partitions.length) {
                        completeMultipartUpload(props.bucket, props.folderName, file.filePath, partitionDetails, props.api.region).then(data => {
                            multipartUploadSuccess(files, file, index, uploadIdDetails);
                        }).catch((error) => {
                            if (files) {
                                let count = index + 1;
                                seriesUpload(files, count, uploadIdDetails)
                            }
                            resetFieldsonError(error, file, index);
                        });
                    } else {
                        let index1 = (Math.floor(fileUploadIdDetails.Parts.length / batchSize) * 100);
                        uploadedParts = index1;
                        let subPartition = partitions.slice(uploadedParts, uploadedParts + batchSize)
                        batchFileExecution(partitions, subPartition, partitionDetails, file, uploadedParts, batchSize, index, files, uploadIdDetails);
                    }
                } else {
                    let subPartition = partitions.slice(uploadedParts, batchSize)
                    batchFileExecution(partitions, subPartition, partitionDetails, file, uploadedParts, batchSize, index, files, uploadIdDetails);
                }
            }
        }, 60);
    }
    function batchFileExecution(partitions, subPartition, partitionDetails, file, uploadedParts, batchSize, index, files, uploadIdDetails) {
        let uploadedPartsbatch = 0;
        let fileCount = parseInt(index);
        for (let [key1, value] of Object.entries(subPartition)) {
            const key = uploadedParts + Math.floor(key1) + 1;
            partitionDetails.details[Math.floor(key)] = { partitionName: file.name.split('.')[0] + '_' + key + '.txt', partitionId: Math.floor(key) };
            postPresignedURL(props.bucket, props.folderName, file.filePath, partitionDetails, key, props.api.region ).then((data) => {
                multiUploadtoS3(data.data.url, value).then((res) => {
                    ++uploadedParts;
                    ++uploadedPartsbatch;
                    partitionDetails.details[key]['E-Tag'] = res.headers.etag.replace(/"/g, '');
                    //api.chunkSize = api.chunkSize + value.size;
                    updateProgressBarWithChunks(uploadedParts, file, partitions.length, index);
                    if (uploadedPartsbatch === subPartition.length) {
                        if (uploadedParts === partitions.length) {
                            completeMultipartUpload(props.bucket, props.folderName, file.filePath, partitionDetails,props.api.region).then(data => {
                                multipartUploadSuccess(files, file, index, uploadIdDetails);
                            }).catch((error) => {
                                if (files && fileCount === index) {
                                    let count = index + 1;
                                    fileCount = count;
                                    seriesUpload(files, count, uploadIdDetails)
                                }
                                resetFieldsonError(error, file, index);
                            });
                        } else {
                            let subPart = partitions.slice(uploadedParts, uploadedParts + batchSize);
                            batchFileExecution(partitions, subPart, partitionDetails, file, uploadedParts, batchSize, index, files, uploadIdDetails);
                        }
                    }
                }).catch((error) => {
                    if (files && fileCount === index) {
                        let count = index + 1;
                        fileCount = count;
                        seriesUpload(files, count, uploadIdDetails)
                    }
                    resetFieldsonError(error, file, index);
                });
            }).catch((error) => {
                if (files && fileCount === index) {
                    let count = index + 1;
                    fileCount = count;
                    seriesUpload(files, count, uploadIdDetails)
                }
                resetFieldsonError(error, file, index);
            });
        }
    }
    function uploadtoS3(data, acceptedFiles, multi, index) {
        let file = new FormData();
        let fileData = data.fields;
        for (let [key, value] of Object.entries(fileData)) {
            file.append(key, value)
        }
        file.append('acl', 'bucket-owner-full-control')
        file.append('file', acceptedFiles);
        return api.uploadPresignedFile(data.url, file, (e) => { onUploadProgress(e, acceptedFiles, index) });
    }
    function multiUploadtoS3(data, filePart) {
        let file = new FormData();
        file.append('file', filePart);
        return api.multiUploadtoS3(data, filePart);
    }
    function getMultipartId(bucket, folderName, fileName,bucket_region) {
        let folder1 = folderName === " " ? fileName : folderName + '/' + fileName;
        const query = { bucket: bucket, object_key: folder1, region:bucket_region };
        let qryStr = queryString.stringify(query);
        return api.getMultipartId(qryStr);
    }
    function getPresignedURL(bucketName, folderName, fileName, bucket_region, confirmRewrite) {
        let folder1 = folderName === " " ? fileName : folderName + '/' + fileName;
        const query = { bucket: bucketName, object_key: folder1, region:bucket_region, confirmRewrite:confirmRewrite };
        let qryStr = queryString.stringify(query);
        return api.getPresignedUrl(qryStr);
    }
    function postPresignedURL(bucket, folderName, fileName, partitionDetails, partNo, bucket_region) {
        let folder1 = folderName === " " ? fileName : folderName + '/' + fileName;
        let data = {
            "bucket": bucket,
            "object_key": folder1,
            "upload_id": partitionDetails.multiPartId,
            "part_number": partNo,
            "region":bucket_region,
            "apicalltype": "getSignedUrl"
        }
        return api.postPresignedUrl(data);
    }
    function onUploadProgress(event, file, index) {
        var percentCompleted = Math.round((event.loaded * 100) / event.total);
        setProgress(file, index, percentCompleted);
        // setPercentCompleted(percentCompleted);
    }
    function updateProgressBarWithChunks(uploadedParts, file, totalParts, index) {
        var percentCompleted = ((uploadedParts / totalParts) * 100).toFixed(2);
        setProgress(file, index, percentCompleted);
        // setPercentCompleted(percentCompleted);
    }
    function completeMultipartUpload(bucket, folderName, fileName, partitionDetails,bucket_region) {
        let folder = folderName === " " ? '' : folderName + '/';
        //const query = { bucket: bucket, object_key: folder + fileName, upload_id: partitionDetails.multiPartId };
        //let qryStr = queryString.stringify(query);
        let data = {
            "bucket": bucket,
            "object_key": folder + fileName,
            "upload_id": partitionDetails.multiPartId,
            "parts": [],
            "region":bucket_region,
            "apicalltype": "completemultipart"
        };
        partitionDetails.details.map(x => {
            if (x) {
                data.parts.push({ PartNumber: x.partitionId, ETag: x['E-Tag'] })
            }
        })
        return api.postPresignedUrl(data);
    }
    function formatBytes(bytes, decimals) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    function resetFieldsonError(error, file, index) {
        file['failed'] = true;
        setProgress(file, index, null);
        $(window).unbind('beforeunload');
    }
    function multipartUploadSuccess(files, file, index, uploadIdDetails) {
        if (files) {
            let count = index + 1;
            seriesUpload(files, count, uploadIdDetails)
        }
        file['failed'] = false;
        setMultipartDetailsArray(index, false)
        setProgress(file, index, 100);
        $(window).unbind('beforeunload');
    }
    function uploadMoreFiles(event) {
        resetProgress();
        setOpenAlert();
        setDisable(false);
        props.refreshData(null, null, null);
        $(window).unbind('beforeunload')
    }
    function clearMultipartbyId(bucket, folderName, fileName, partitionDetails,bucket_region) {
        let folder1 = folderName === " " ? '' : folderName + '/';
        folder1 = folder1 + fileName;
        const query = { bucket: bucket, object_key: folder1, upload_id: partitionDetails.multiPartId, region:bucket_region };
        let qryStr = queryString.stringify(query, { encode: false });
        return api.clearMultipartbyId(qryStr);
    }
    function handleClose(event) {
        if (event.target.innerText === 'YES') {
            startUploadFile([failedFilePath], failedFilePath, 0, 'Yes', [])
        }
        else {
            failedFilePath['failed'] = true;
            setProgress(failedFilePath, 0, null);
            setSeriesUploaded(true);
        }
        setOpenConfirm(false);
      }
    function handleCancelMultipart(event, progressData, data, index) {
        clearMultipartbyId(data.bucket, data.folderName, data.fileName, data.partitionDetails,props.api.region).then(data => {
            if (data.ResponseMetadata.RequestId) {
                props.api.cancelMultipartUpload = true;
                progressData['canceled'] = true;
                progressData['filePath'] = progressData.path;
                setProgress(progressData, index, 0)
                setMultipartDetailsArray(index, false)
                // props.sendTostMessage(`File upload of ${data.fileName} cancelled successfully.`, 'success');
            }
        });
    }
    return (
        <>
            {/* {percentCompleted > 0 ? <LinearProgress className="progress" variant="determinate" value={percentCompleted} /> : ''} */}
            <section
                className={`dropzone ${disable ? 'disabled' : ''}`}>
                <div className="content" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files/Folder here ...</p> : percentCompleted.length > 0 ? <p></p> :
                                <p>Drag 'n' drop some files or a folder here, or click to select files</p>
                    }
                    {
                        percentCompleted.length > 0 ? <div></div> : <div>
                            <div><b>Note:</b></div>
                            <div>1. Filename should not exceed by 64 characters</div>
                            {/* <div>Filename should not contain any special characters other than below</div>
                            <ul>
                                <li>Forward slash ( / )</li>
                                <li>Exclamation point ( ! )</li>
                                <li>Hyphen ( - )</li>
                                <li>Underscore ( _ )</li>
                                <li>Period ( . )</li>
                                <li>Asterisk (*)</li>
                                <li>Single quote ( ' )</li>
                                <li>Open parenthesis ( ( )</li>
                                <li>Close parenthesis ( ) )</li>
                            </ul> */}
                            <div>2. Avoid the following characters in a key name because of significant special handling for consistency across all applications.</div>
                            <ul className="note-list">
                                <li>Backslash ("\")</li>
                                <li>Left curly brace</li>
                                <li>Non-printable ASCII characters (128–255 decimal characters)</li>
                                <li>Caret ("^")</li>
                                <li>Right curly brace</li>
                                <li>Percent character ("%")</li>
                                <li>Grave accent / back tick ("`")</li>
                                <li>ORight square bracket ("]")</li>
                                <li>Quotation marks</li>
                                <li>'Greater Than' symbol</li>
                                <li>Left square bracket ("[")</li>
                                <li>Tilde ("~")</li>
                                <li>'Less Than' symbol</li>
                                <li>'Pound' character ("#")</li>
                                <li>Vertical bar / pipe ("|")</li>
                            </ul>
                        </div>
                    }
                </div>

            </section>
            <div>
                <Dialog
                    open={openConfirm}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title"></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            File/Folder with same already exists, Are you sure you want to replace it?  Click ‘YES’ to continue.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Yes
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {percentCompleted.length > 0 ? <div className="uploadMain">
                <div><Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!seriesUploaded}
                    // startIcon={<CloudUploadIcon />}
                    onClick={uploadMoreFiles}
                >
                    Upload More Files
                    </Button>
                </div>
                <Collapse className="alertMain" in={!openAlert === false}>
                    <Alert onClose={() => { setOpenAlert() }} severity="info">
                        {openAlert}
                    </Alert>
                </Collapse>
                {renderProgressBar}
            </div>
                : ''}
        </>
    );
}
