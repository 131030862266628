import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles(theme => ({
    root: {
        width: 360,
        maxHeight: 300,
        backgroundColor: theme.palette.background.paper,
    },
    divider: {
        marginRight: 10
    },
    typography: {
        padding: theme.spacing(2),
    },
    tabPanel: {
        paddingTop: 0,
        paddingBottom: 0
    },
    header: {
        lineHeight: '25px'
    },
    inline: {
        display: 'inline',
    },
    notification: {
        marginRight: 10,
        marginTop: 5
    },
    chipNone: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        border: 'none'
    },
    listItemText: {
        cursor: 'pointer'
    }
}));

export default function Notification(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notification, setNotification] = useState([]);
    const [render, setRender] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    async function initializeData() {
        let notifications = await props.api.getNotifications();
        var notificationData = await notifications;
        /* notifications.then((res) => {
        }); */
        setNotification(notificationData);

    }
    function getTime(input) {
        let start = new Date();
        let end = new Date(input);
        let differance = start - end;
        let days = Math.floor(differance / 86400000);
        let hours = Math.floor((differance % 86400000) / 3600000);
        if (days > 0) return `${days} day${days > 1 ? `s` : ``} ago`;
        else return `${hours} hour${hours > 1 ? `s` : ``} ago`;
    }
    useEffect(() => {
        setTimeout(() => initializeData(), 300);
        return () => {
        };
    }, [])
    useEffect(() => {
        let count = 0;
        let filteredNotifications = [];
        notification.map((x) => {
            if (x.restored || x.copied) {
                x['date'] = new Date(x.created_at);
                filteredNotifications.push(x)
            }
        });
        filteredNotifications.sort((a, b) => b.date - a.date);
        filteredNotifications.map((x) => {
            if (!x.notified_through_homescreen) count++;
        });
        setUnreadCount(count);
        let renderVal;
        if (filteredNotifications.length > 0) {
            renderVal = filteredNotifications.map((x, i) => {
                return <> <ListItem className={classes.tabPanel} alignItems="flex-start" key={Math.random()}>
                    <ListItemIcon>
                        <NotificationsActiveIcon color={x.notified_through_homescreen ? "primary" : "secondary"} />
                    </ListItemIcon>
                    <ListItemText
                        onClick={(event) => window.open(x.app_url_for_file)}
                        className={classes.listItemText}
                        key={Math.random()}
                        primary={x.object_key}
                        secondary={
                            <React.Fragment key={Math.random()}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    <span style={{ float: `left` }}>{x.copied ? 'file copy Successful' : 'file is retrieved.'}</span><span style={{ float: `right` }}> {getTime(x.created_at)}</span>
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                    {(filteredNotifications.length - 1) === i ? '' : <Divider className={classes.divider} variant="inset" component="li" key={`${x.id}a`} />}</>
            });
        }
        else {
            renderVal = <Chip
                className={classes.chipNone}
                //icon={<AccountCircle />}
                label={`There are no notifications to display`}
                clickable
                color="primary"
                variant="outlined"
            />
        }
        setRender(renderVal);
    }, [notification]);

    async function handleClick(event) {
        setAnchorEl(event.currentTarget);
    };

    async function handleClose() {
        setAnchorEl(null);
        let ids = [];
        notification.map((x) => { if (!x.notified_through_homescreen && (x.restored || x.copied)) ids.push(x) });
        let data = { id: ids.map(x => x.id) };
        if (ids.length > 0) {
            let res = await props.api.setNotificationsasRead(data);
            let result = await res;
            if (result[200] === "OK") initializeData();
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>

            <IconButton className={classes.notification} color="inherit" onClick={handleClick}>
                <Badge badgeContent={unreadCount} color="primary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List key={Math.random()}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    /*  subheader={
                         <ListSubheader className={classes.header} component="div" id="nested-list-subheader">
                             Notifications
                         </ListSubheader>
                     } */
                    className={classes.root}
                >
                    {render}
                </List>
            </Popover>
        </div>
    );
}
