import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import { Button } from 'primereact/button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import { ReactComponent as LoadingSVG } from '../Assets/loading.svg';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import LinkIcon from '@material-ui/icons/Link';
import '../Css/deleteFile.css';

function getRenderSource(props){
    let render = ''
    if ( props.deleteData.length && props.orgBucketName )
        {
            render = props.deleteData.map( ( x, i ) =>
            {
                return <Chip
                    className="chip"
                    key={ x.Key + i }
                    label={ props.orgBucketName + '/' + x.Key }
                    color="primary"
                    variant="outlined"
                    title={ props.orgBucketName + '/' + x.Key }
                />
            } );
        }
        return render
}

function getDeleteStatusRender(deleteStatus,props){
    let render = ''
    if ( deleteStatus.length > 0 )
        {
            let _cellRender = deleteStatus.map( ( x, i ) =>
            {
                return <div className="status-row">
                    <div className="status-cell width-250" title={ props.orgBucketName + '/' + x.Key }>
                        { x.Name }
                    </div>
                    <div className="status-cell">
                        { x.status === 'progress' ? <LoadingSVG title="In Progress" /> : '' }
                        { x.status === 'Failed' && x.status !== 'progress' ?
                            <ErrorIcon style={ { color: '#e85445' } } title="Failed" /> : <CheckCircleIcon style={ { color: '#42d662' } } title="Completed" /> }
                    </div>
                </div>
            } );
            render = <div className="status-main">
                <div className="status-table">
                    { _cellRender }
                </div>

            </div>
        }
        return render
}

export default function DeleteFile ( props )
{
    const [ showBackDrop, setShowBackDrop ] = useState( false );
    const [ renderSource, setRenderSource ] = useState( '' );
    const [ deleteStatus, setDeleteStatus ] = useState( [] );
    const [ deleteStatusRender, setDeleteStatusRender ] = useState();
    async function initializeData ()
    {
        props.api.deleteStatus = [];
    }
    useEffect( () =>
    {
        setTimeout( () => initializeData(), 300 );
        return () =>
        {
        };
    }, [] )
    useEffect( () =>
    {
        setRenderSource(getRenderSource(props));        
    }, [ props.deleteData, props.orgBucketName ])
    useEffect( () =>
    {
        setDeleteStatusRender( getDeleteStatusRender(deleteStatus,props) );
    }, [ deleteStatus ] )
    function statusPostDelete ( all, data, index, selection, status )
    {
        if ( all )
        {
            props.api.deleteStatus = [];
            data.map( ( x, i ) =>
            {
                props.api.deleteStatus.push( { Name: x.Name, status: status, Key: x.Key } )
            } );
        } else
        {
            let array = Object.assign( [], props.api.deleteStatus );
            array.splice( index, 1, { Name: selection.Name, status: status, Key: selection.Key } );
            props.api.deleteStatus = Object.assign( [], array );
        }
        setDeleteStatus( props.api.deleteStatus );
    }
    function sequentialDeleteCall ( data, count )
    {
        return props.api.deleteFile( props.orgBucketName, data.Key, props.api.region ).then( result =>
        {
            globalDore( data, count, result[ 200 ] );

        } ).catch( error =>
        {
            if (error.response.status === 403) {
                props.sendTostMessage('You are not authorized to do this operation, please check with your admin.', 'error');
            } else {
                statusPostDelete(false, props.deleteData, count, data, 'Failed');
                props.sendTostMessage('Delete operation failed , please try again.', 'error');
                count =  count + 1
                return count;
            }
            setShowBackDrop(false);
            return '';
        } );
    }
    async function deleteFile ()
    {
        setShowBackDrop( true );
        statusPostDelete( true, props.deleteData, null, null, 'progress' );
        for ( let i = 0; i < props.deleteData.length; i++ )
        {
            let data = props.deleteData[ i ];
            let _res = await sequentialDeleteCall( data, i );
            await _res;
        }
        props.refreshData();
    }

    async function globalDore ( data, count, status )
    {
        if( ['Failed',403].indexOf(status) !== -1 )
        {
            statusPostDelete( false, props.deleteData, count, data, 'Failed' );
            props.sendTostMessage( 'Delete operation failed , please try again.', 'error' );
            count =  count + 1
            return count;
        }
        if ( status === 'OK' )
        {
            props.sendTostMessage( 'Delete operation Success.', 'success' );
            statusPostDelete( false, props.deleteData, count, data, 'Complete' );
            count =  count + 1
            return count;
        } else
        {
            props.sendTostMessage( `Folder ${ data.Name } should be empty to be deleted.`, 'error' );
            statusPostDelete( false, props.deleteData, count, data, 'Failed' );
            setShowBackDrop( false );
            count = count + 1
            return count;
        }
    }

    return (
        <div>
            { !deleteStatusRender ? <div>
                <Backdrop className="backDrop" open={ showBackDrop }>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="delete_note">
                    <div><ReportProblemIcon /> Deleting the specified objects can't be undone.</div>
                    <div>
                        <a href="https://docs.aws.amazon.com/console/s3/deleteobject" target="_blank">Learn More</a>
                        <a href="https://docs.aws.amazon.com/console/s3/deleteobject"><LinkIcon /></a>
                    </div>
                </div>
                <div className="note">
                    <div><b>Note:</b> As AWS S3 is highly available, deleted files might appear in the bucket until S3 completely delete them.</div>
                </div>
                <div className="source">
                    <span className="source-main">Specified File/s: </span>{ renderSource }
                </div>
                <div>
                    <strong>Permanently Delete?</strong>
                </div>
                <div class="row button-main">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-start">
                        <Button className="deleteButton p-button-danger" label="Delete File" icon="pi pi-trash" disabled={ false } onClick={ () => deleteFile() } />
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                        <Button className="closeButton" label="Close" icon="pi pi-times" disabled={ false } onClick={ () => props.deleteSuccessful() } />
                    </div>
                </div>
            </div> : deleteStatusRender }
        </div>
    );
}
