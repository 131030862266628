import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chips } from 'primereact/chips';
import { Slider } from 'primereact/slider';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import '../Css/shareFile.css';

export default function ShareFile(props) {
    const [emailValue, setEmailValue] = useState([]);
    const [hours, setHours] = useState(0);
    const [disableButton, setDisableButton] = useState(true);
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [alertText, setAlertText] = useState();
    async function initializeData() {
    }
    useEffect(() => {
        setTimeout(() => initializeData(), 300);
        return () => {
        };
    }, [])
    function setEmail(e) {
        let outputEmails = [];
        let reg = new RegExp(/^\w+([\.-]?\w+)*@(aenetworks|aetvn)(\.\w{2,3})+$/);
        let rejectedEmails = [];
        e.value.map(x => {
            let arr = x.split(';');
            if (arr.length === 1 && reg.test(x))
                outputEmails.push(x);
            else if (arr.length > 1)
                arr.map(y => {
                    if (reg.test(y)) outputEmails.push(y);
                    else rejectedEmails.push(y);
                })
            else
                rejectedEmails.push(x);
        })
        if (rejectedEmails.length > 0) {
            setAlertText(`${rejectedEmails} is/are incorrect. Please enter valid Email IDs.`);
        } else {
            setAlertText();
        }
        if (outputEmails.length > 0 && hours > 0) {
            setDisableButton(false);
        }
        else setDisableButton(true);
        setEmailValue(outputEmails);
    }
    function assignHours(e) {
        setHours(e.value)
        if (emailValue.length > 0 && e.value > 0) {
            setDisableButton(false);
        }
        else setDisableButton(true);
    }
    async function handleShare(event) {
        setShowBackDrop(true);
        let data = {
            "user_email": props.api.user.email,
            "emails": emailValue,
            "expires_in": hours * 60 * 60
        }
        props.api.shareFile(props.orgBucketName, props.shareData.Key, data, props.api.region).then(res => {
            props.sendTostMessage(`${props.shareData.Name} Shared successfully.`, 'success');
            props.shareSuccessful();
            setShowBackDrop(false);
        }).catch(error => {
            if (error.response.status === 403) {
                props.sendTostMessage('You are not authorized to do this operation, please check with your admin.', 'error');
            } else {
                props.sendTostMessage(error.message, 'error');
            }
            setShowBackDrop(false);
        })
    };

    return (
        <div>
            <Backdrop className="backDrop" open={showBackDrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="note"><b>Note:</b> Can only share an object with your A&E counterparts. </div>
            <div className="source">
                <span className="source-main">Object being Shared: </span>
                <Chip
                    className="chip"
                    label={props.orgBucketName + '/' + props.shareData.Key}
                    color="primary"
                    variant="outlined"
                />
            </div>
            <Collapse className="alertMain" in={!alertText === false}>
                <Alert onClose={() => { setAlertText() }} severity="error">
                    {alertText}
                </Alert>
            </Collapse>
            <div className="email">
                <div className="email-title"> <span className="email-title-main">Email ID's : </span><span className="email-title-sub">
                    (Emails can be separated with a <b>';'</b> for Bulk Share. Please click enter to validate the Email ID.)</span></div>
                <Chips value={emailValue} onChange={setEmail} allowDuplicate={false} separator=";"></Chips>
            </div>
            <div className="expiry">
                <div className="expiry-title"> <span className="expiry-title-main">Expiry Hours (max 24 hrs):</span> {hours}</div>
                <Slider className="expiry-bar" animate={true} value={hours} max={24} onChange={assignHours} />
            </div>
            <Button className="shareButton" label="Share File" icon="pi pi-copy" disabled={disableButton} onClick={() => handleShare()} />

        </div >
    );
}
