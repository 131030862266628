import { Subject } from "rxjs";

let fullAccess = new Subject();
let selectedRows = new Subject();
let fullAccess$ = fullAccess.asObservable();
let selectedRows$ = selectedRows.asObservable();

function setFullAccess(data) {
    fullAccess.next(data);
}
function setSelectedRows(data) {
    selectedRows.next(data);
}
function _getTitle(fahrenheit) {
    return "";
}
const util = {
    getTitle: _getTitle,
    fullAccess$: fullAccess$,
    setFullAccess: setFullAccess,
    setSelectedRows: setSelectedRows,
    selectedRows$: selectedRows$
};

/* util.fullAccess$.subscribe(val => {
    setData(val);
}); */ // usage of rxjs

export default util;
